.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4px 0px;
}
.label {
  display: flex;
  align-items: center;
  gap: 8px;
}
.icon {
  width: 12px;
}
