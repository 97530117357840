@import 'src/theme/colors.module.scss';

.container {
    width: 300px;
    display: flex;
    flex-direction: column;
}

.actionContainer {
    display: flex;
    padding: 28px 16px;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 100px;
    object-fit: contain;
}

.linksContainer {
    display: flex;
    flex-direction: column;
    padding: 0;
    // padding-top: 24px;
    margin: 0;
    text-decoration: none;
}

.navItem {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
}

.navItemWrapper {
    display: flex;
    gap: 16px;
    flex-direction: row;
}

.icon {
    width: 25px;
}

.navLink:active {
    color: #009b5c;
}

.langItem {
    margin-bottom: 16px;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 16px;
}

.hamburgerMenu {
    display: none;
}

.langSelectorMobile[dir='ltr'] {
    justify-content: flex-end;
    direction: rtl;
}

.langSelectorMobile[dir='rtl'] {
    justify-content: flex-end;
    direction: ltr;
}
@media (max-width: 850px) {
    .hamburgerMenu {
        display: block;
    }
}

.newItemWrapper {
    background-color: $primary-50;
    border-radius: 8px 0px 8px 0px;
    padding: 1px 6px;
    color: $primary-600;
}
.tagContainer {
    background-color: $primary-50;
    border-radius: 8px;
    margin: 16px;
    margin-bottom: 24px;
    padding: 16px;
}
.contentWrapper {
    display: flex;
    gap: 12px;
}
.iconContainer {
    background-color: $white;
    border-radius: 38px;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.userDataContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.signInLabel {
    margin-bottom: 4px;
}
.signUpLabel {
    color: $primary-600;
    cursor: pointer;
}
