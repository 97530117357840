.container {
  display: flex;
  background-color: #f3fffa;
  border-radius: 16px;
  padding: 16px 24px;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  margin: 40px 7.5% 72px;
}

.certificationContainer {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  padding: 20px 16px 28px;
}

.certificate {
  width: 45%;
}

.header {
  color: #009b5d;
  line-height: 38px;
}

.description {
  color: #002d5d;
  margin: 0;
}

.badgesContainer {
  display: flex;
  align-items: baseline;
  gap: 32px;
}

.badgeContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 16px;
  text-align: center;
  width: 30%;
  align-items: center;
}

.badge {
  width: 56%;
}

.badgeMobile {
  display: none;
}

.badgeDescription {
  width: 85%;
  color: #009b5d;
  line-height: 30px;
  text-align: center;
  margin: 0;
  color: #002d5d;
}

.mobileButton {
  display: none;
}

.desktopButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  width: 60%;
  height: 48px;
  background: #009b5d;
  border: 1px solid #009b5d;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: white;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
}

@media (max-width: 2560px) {
  .certificate {
    width: 30%;
  }
}

@media (max-width: 1024px) {
  .container {
    overflow: hidden;
    gap: 0;
  }

  .certificationContainer {
    width: 70%;
  }
  .badgesContainer {
    width: unset;
  }

  .badgeContainer {
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    width: 93%;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
    background-color: transparent;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 17px;
    gap: 0;
  }

  .certificationContainer {
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }

  .certificate {
    width: 30%;
  }

  .desktopButton {
    display: none;
  }

  .mobileButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    width: 100%;
    height: 48px;
    background: #e6fbf2;
    border: 1px solid #e6fbf2;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #00673e;
    margin-top: 12px;
    font-family: "CeraPro-Medium";
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
  }

  .mobileButton:hover {
    color: #00673e;
  }

  .badgesContainer {
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    width: 100%;
  }

  .badgeContainer {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 12px;
    background: #f9fafb;
    border: 1px solid #f9fafb;
    border-radius: 16px;
  }

  .badge {
    display: none;
  }

  .badgeMobile {
    display: block;
    width: 23%;
    aspect-ratio: 1/1;
  }
}
