.headerSection {
  display: flex;
  justify-content: space-between;
  padding-bottom: 64px;
  border-bottom: 1px solid #475467;
  align-items: center;
}

.headersContainer {
  display: flex;
  flex-direction: column;
}

.header {
  color: white;
  margin-bottom: 0;
}

.linksButtonsContainer {
  display: flex;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.sellButton {
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding: 12px 20px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  text-decoration: none;
  color: #344054;
}

.sellButton:hover {
  color: #344054;
}

.buyButton {
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding: 12px 20px;
  background: #009b5d;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: white;
  border: none;
  text-decoration: none;
}

.buyButton:hover {
  color: white;
}

.ContactUsButton {
  display: flex;
  gap: 10px;
  padding: 12px 20px;
  font-weight: 600;
  border: 1px solid #ffffff;
  border-radius: 8px;
  color: white;
  align-items: center;
}
.hotlineTag {
  text-decoration: none;
}

.linksSection {
  display: flex;
  padding: 64px 0;
  justify-content: space-between;
}

.companyBriefContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.logo {
  width: 142px;
}
.logoAr {
  width: 112px;
}

.brief {
  color: #eaecf0;
  width: 320px;
  height: 80px;
}

.linksContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 32px;
}

.navGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.groupHeader {
  line-height: 20px;
  color: #98a2b3;
}

.navItem {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #eaecf0;
  opacity: 0.9;
  cursor: pointer;
}

.navItem:hover {
  color: #d0d5dd;
}

.newBadge {
  color: white;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  width: 42px;
  height: 22px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  padding: 32px 0px 0px;
  border-top: 1px solid #475467;
}

.copyRights {
  line-height: 24px;
  color: #98a2b3;
}

.socialMediaLinks {
  display: flex;
  gap: 24px;
}

.socialMediaLogo {
  width: 24px;
  height: 24px;
}
@media (max-width: 850px) {
  .bottomSection {
    flex-direction: column;
    gap: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .header {
    text-align: center;
  }
}
@media (max-width: 768px) {

  .header {
    text-align: center;
  }

  .headerSection {
    flex-direction: column;

    gap: 12px;
    padding: 16px;
  }

  .linksButtonsContainer {
    flex-direction: column-reverse;
  }

  .buttonsContainer {
    flex-direction: column;
    width: 100%;
  }

  .buyButton {
    width: 100%;
  }

  .sellButton {
    width: 100%;
  }

  .ContactUsButton {
    width: 100%;
    justify-content: center;
  }
  .hotlineTag {
    text-decoration: none;
    width: 100%;
    justify-content: center;
  }

  .linksSection {
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    padding-top: 0;
    border-bottom: 1px solid #475467;
  }

  .linksContainer {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .brief {
    width: 100%;
  }

  .navGroup {
    width: 40%;
  }

  .socialMediaLinks {
    gap: 12px;
  }

  .bottomSection {
    flex-direction: column;
    gap: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
