@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";

.titleContainer {
  .title {
    margin-bottom: 4px;
  }

  .subtitle {
    color: $gray-500;
  }
}

.locationContainer {
  margin-top: 16px;

  .title {
    margin-bottom: 8px;
    color: $gray-700;
  }

  .locationDetailsContianer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // height: 10%;
    // max-height: 92px;
    border: 1px solid $gray-300;
    box-shadow: 0px 1px 2px $gray-900-opac;
    border-radius: 8px;
    padding: 16px;
    @media screen and #{$mobileView} {
      // max-height: 84px;
    }

    .locationDetails {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      .icon {
        width: 13.33px;
        height: 16.67px;
        margin-inline-end: 11.33px;
        margin-top: 3px;
      }

      .nameContainer {
        display: flex;

        .name {
          color: $primary-600;
          margin-bottom: 4px;
        }
      }

      a {
        text-decoration: none;
        cursor: pointer;
        display: flex;
        color: $gray-500;

        .icon {
          width: 12px;
          height: 12px;
          margin-inline-start: 13px;

          &.iconReversed {
            transform: rotateY(180deg);
          }
        }
      }
    }

    .directionsDetails {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-self: flex-start;
      padding-inline-start: 24.66px;

      .instructions {
        color: $gray-500;
      }

      @media screen and #{$mobileView} {
        align-self: flex-start;
      }

      .directions {
        color: $gray-500;
        margin-inline-end: 13px;
      }
    }
  }
}

.daysContainer {
  margin-top: 16px;

  .subtitle {
    color: $gray-700;
    margin-bottom: 4px;
  }

  .days {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .day {
      width: 20%;
      min-width: 172px;
      height: 64px;
      margin-inline-end: 8px;
      margin-block-end: 8px;
      background-color: $gray-200;
      border: none;
      border-radius: 8px;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      color: $gray-600;

      @media screen and #{$mobileView} {
        min-width: 101px;
        width: 47%;
      }

      &.unavailable {
        background-color: $gray-25;
        cursor: not-allowed;

        .date {
          color: $gray-400;
        }

        .dayName {
          color: $gray-400;
        }
      }

      &.selected {
        background-color: $primary-100;
        border: 1px solid $primary-600;

        .date {
          color: $primary-600;
        }

        .dayName {
          color: $primary-600;
        }
      }

      .date {
        margin-top: 16px;
      }

      .dayName {
        margin-bottom: 16px;
      }
    }
  }
}
.slotsContainer {
  margin-top: 16px;

  .disclaimerContainer {
    display: flex;

    .icon {
      width: 15px;
      height: 16.67px;
      margin-inline-end: 6.5px;
    }
  }

  .subtitle {
    color: $gray-700;
    margin-bottom: 4px;
  }

  .slots {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;

    .slot {
      height: 40px;
      margin-inline-end: 8px;
      margin-bottom: 8px;
      background-color: $gray-200;
      border: none;
      border-radius: 8px;
      display: inline-flex;
      justify-content: center;
      padding-block: 6px;
      padding-inline: 4%;
      align-items: center;

      @media screen and #{$mobileView} {
        min-width: 100px;
        width: 30%;
      }

      &.unavailable {
        background-color: $gray-25;
        cursor: not-allowed;

        .time {
          color: $gray-400;
        }
      }

      &.selected {
        background-color: $primary-100;
        border: 1px solid $primary-600;

        .time {
          color: $primary-600;
        }
      }

      .time {
        // margin-bottom: 16px;

        color: $gray-600;
      }
    }
  }

  @media screen and #{$mobileView} {
    padding-bottom: 200px;
    z-index: 1;
  }
}
