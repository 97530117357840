.container {
  display: flex;
  flex-direction: column;
  padding: 4.5% 5.5%;
  text-align: center;
  justify-content: flex-start;
  gap: 24px;
  overflow: hidden;
}

.headerSection {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.header {
  color: #009b5d;
}

.subHeader {
  color: #002d5d;
  margin: 0;
  margin-top: -4px;
}

.contentContainer {
  display: flex;
  gap: 77px;
  justify-content: space-between;
  padding: 0 1.5%;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stepsImages {
  border-radius: 16px;
}

.stepsList {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.stepItem:last-child .block {
  overflow: hidden;
}

.stepsList .stepItem {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-grow: 1;
  gap: 6px;
}

.title {
  display: block;
  line-height: 30px;
  color: #002d5d;
  margin: 0;
  margin-bottom: 8px;
}

.descriptionSection {
  float: left;
  margin-left: 16px;
  margin-bottom: 56px;
  margin-top: 8px;
}

.descriptionSection:last-child {
  margin-bottom: 0px;
}

.descriptionSectionar {
  margin-right: 16px;
  margin-left: 0;
  margin-top: 8px !important;
}

.description {
  float: left;
  margin-left: 0;
  line-height: 28px;
  color: #667085;
}

.block {
  width: 40px;
  height: 40px;
  background: #e6fbf2;
  border: 5px solid #f3fffa;
  float: left;
  border-radius: 40px;
}

.rtl {
  float: right;
  text-align: right;
}

.ar {
  float: right;
  left: 0;
  text-align: right;
}

.block:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: 20px;
  left: 20px;
  transform: translate(-50%, -50%);
  background: #009b5d;
  border-radius: 100%;
}

.ar::before {
  right: 12px;
  left: 0;
}

.block:after {
  content: "";
  height: 100%;
  position: absolute;
  border: 1px solid #009b5d;
  top: 20px;
  left: 19px;
}

.ar::after {
  right: 19px;
  left: 0;
  border: none;
  border-right: 1px solid #009b5d;
}

.actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  width: 50%;
  height: 48px;
  background: #009b5d;
  border: 1px solid #009b5d;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: white;
  font-weight: 700;
  text-decoration: none;
  margin-left: 50px;
  font-size: 16px;
}

.actionButton:hover {
  color: #00673e;

  color: white;
}

.lastBlock::after {
  border: none;
}

@media (max-width: 1024px) {
  .contentContainer {
    width: 100%;
    gap: 0;
    align-items: center;
    justify-content: center;
  }

  .stepsImage {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .container {
    padding-top: 0;
    width: 100%;
  }

  .stepsImage {
    display: none;
  }

  .block {
    margin: 0;
  }

  .header {
    padding-top: 30px;
  }

  .title {
    margin: 0;
    float: left;
  }

  .contentContainer {
    display: block;
  }

  .descriptionSection {
    margin: 0;
    float: none;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
  }

  .actionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    width: 100%;
    height: 48px;
    background: #e6fbf2;
    border: 1px solid #e6fbf2;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #00673e;
    margin-top: 12px;
    margin-left: 0;
  }
}
