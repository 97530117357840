@import 'src/theme/dimensions';
@import 'src/theme/colors.module.scss';


.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.gridContainer {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;

  @media screen and #{$desktopView} {
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  @media screen and #{$desktopView} {
    padding: 32px 80px 56px 80px;
  }
}

.count {
  margin-top: 12px;
  display: flex;
  gap: 4px;
  color: $gray-700;
}