@import '../../../theme/dimensions';
@import '../../../theme/colors.module';

.rootContainer {
  padding-inline: 16px;
  padding-block: 24px;
  border-radius: 16px;
  border: 1px solid $gray-200;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.rootDivider {
  height: 0px;
  align-self: stretch;
  border-top: 1px solid $gray-200;
}

.makeIconContainer {
  padding: 17px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  background: $gray-50;
}

.makeIconWrapper {
  width: 72px;
  height: 46px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.statusTagContainer {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.carInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.carInfoRoot {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
}

.carInfoSeperated {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.carBookingMoentaryRoot {
  display: flex;
  flex-direction: column;
  // gap: 12px;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cardInfoTitle {
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  align-items: center;
}

.cardInfoValue {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: flex-start;
}

.carInfoTitleText {
  white-space: nowrap;
}

.cardFooter {
  display: grid;
  gap: 16px;
  align-items: start;
  grid-template-columns: max-content 1fr;
  min-height: 75px;
}

.success {
  background: $primary-25;
}

.success p {
  color: $primary-600;
}

.warning {
  background: $warning-25;
}

.warning p {
  color: $warning-600;
}

.error {
  background: $error-25;
}

.error p {
  color: $error-600;
}

.title {
  white-space: nowrap;
}

.vehicleImageWrapper {
  border-radius: 8px;
}

.vehicleImage {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 100px;
  min-height: 80px;
  height: 100%;
  border-radius: 8px;
}

.ellipse {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background-color: $gray-500;
  display: inline-block;
}

.redirectionIcon {
  cursor: pointer;
}

.retailCurrency {
  margin-inline-end: 4px;
  display: inline !important;
  color: $gray-500;
}

.retailMetadataRow {
  display: flex;
  justify-content: space-between;
  color: $gray-500;
}

.cancellationTagContainer {
  background-color: $gray-25;
  border: 1px solid $gray-200;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
}

.cancellationTagBtnContainer {
  display: flex;
  margin-inline-start: 10px;
}

.hidden {
  display: none;
}

.imgPlaceholder {
  width: 100px;
  height: 80px;
}
