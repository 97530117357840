@import "src/theme/colors.module.scss";
.container {
  width: 100%;
  border: 1px solid $gray-200;
  border-radius: 16px;
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;

  .icon {
    width: 18px;
    height: 18px;
    margin-inline-end: 11px;
  }

  .title {
    color: $secondary;
  }
}

.dataContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .dataRow {
    display: flex;
    justify-content: space-between;

    .name {
      color: $gray-600;
    }

    .value {
      color: $gray-500;
    }
  }
}
