.container {
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  display: none;
  padding: 14px 35px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
  rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
  rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  z-index: 999;
  max-height: 65px;
  line-height: 1rem;
}

.IconContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  height: 100%;
  justify-content: space-around;
  text-decoration: none;
}

.icon {
  width: 20px;
  height: 20px;
}

.tabName {
  margin: 0;
  color: #98a2b3;
  font-size: 10px;
}

.active {
  color: #00ce7c;
}

@media (max-width: 768px) {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
