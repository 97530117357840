@import 'src/theme/colors.module.scss';

.pageWrapper {
    min-height: 100vh;
}
.headerContainer {
    padding: 11px 5% 19px;
    display: flex;
    justify-content: center;
    position: relative;
    border-bottom: 1px solid $gray-200;
}
.tick {
    position: absolute;
}
.pageTitle {
    color: $secondary;
    font-weight: 600;
}

.contentContainer {
    // padding: 24px 16px;
}
.input {
    border: 1px solid $gray-300;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px $gray-900-opac;
    padding-inline: 14px;
    font-weight: 400;

    line-height: 24px;

    &:focus {
        border: 1px solid $primary-300;
        box-shadow: 0px 0px 0px 4px $primary-50;
        outline: none;
    }
}
.label {
    margin-bottom: 6px;
}

.bottomButtonContainer {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    padding: 12px 16px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
        rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
        rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    z-index: 1000;
    max-height: 65px;
    line-height: 1rem;
    right: auto;
    left: 0;
}
.inputIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.fieldContainer {
    margin-bottom: 18px;
}
.inputContainer {
    position: relative;
    margin-bottom: 5px;
}
.emailInputContainer {
    position: relative;
    margin-bottom: 32px;
}
.inputError {
    border-color: $error-300 !important;
    box-shadow: 0px 0px 0px 4px $error-100 !important;
}
.inputIconRTL {
    left: 3%;
    right: auto;
}
.inputIconLTR {
    right: 3%;
    left: auto;
}
