.carousel_slider_container {
  width: 100%;
  overflow-x: hidden;
  position: relative;
  border-radius: inherit;
  direction: ltr;
  // height: 100%;
  .imgContainer {
    display: -webkit-box;
  }
  .indexDotsContainer {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
  }

  .indexArrowsContainer {
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0px 8px;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;

    button {
      width: 28px;
      height: 28px;
      padding: 4px;
      border-radius: 40px;
      border: 1px;
      gap: 8px;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: all;
    }
  }
}
