@import "src/theme/colors.module.scss";
.container {
  width: 70vw;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.content_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}
.item_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  td {
    padding: 10px 12px;
    border: 1px solid #eaecf0;
    border-top-width: 1px;
    border-inline-start-width: 1px;
    border-bottom-width: 0px;
    border-inline-end-width: 0px;
    text-align: start !important;

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: fit-content;
      margin: 0px auto;

      li {
        width: fit-content;
      }
    }
  }

  tr:first-child td:first-child {
    border-radius: 0px !important;
    border-start-start-radius: 4px !important;
  }

  tr:first-child td:last-child {
    border-start-end-radius: 4px !important;
  }

  tr:last-child td:first-child {
    border-end-start-radius: 4px !important;
  }

  tr:last-child td:last-child {
    border-end-end-radius: 4px !important;
    border-width: 1px !important;
  }

  tr:last-child td {
    border-top-width: 1px;
    border-inline-start-width: 1px;
    border-bottom-width: 1px;
    border-inline-end-width: 0px;
  }

  tr td:last-child {
    border-top-width: 1px;
    border-inline-start-width: 1px;
    border-bottom-width: 0px;
    border-inline-end-width: 1px;
  }

  td + td {
    background: $gray-50;
    text-align: center !important;
  }
}
