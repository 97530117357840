@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";

.container {
  display: flex;
  width: 5%;
  flex-direction: column;

  &:not(:last-child) {
    flex: 1;
  }
}

.iconAndConnector {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.connector {
  height: 2px;
  width: 100%;
  background-color: $gray-200;

  &.active {
    background-color: $primary-600;
  }

  &.last {
    width: 0px;
  }
}

.titleContainer {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    color: $gray-700;
    margin-bottom: 0px;

    &.completed {
      color: $primary-700;
    }

    &.noWrap {
      white-space: nowrap;
    }
  }

  .subtitle {
    color: $gray-500;
    min-width: 13vw;

    &.completed {
      color: $primary-600;
    }
  }

  @media screen and #{$mobileView} {
    display: none;
  }
}
