@use "../../../components/shared/typography/styles.module.scss";

.container {
  display: flex;
  padding: 0.5% 5% 0;
  background-color: #e9eaff;
  border-radius: 8px;
  margin: 0 5.5%;
  padding-right: 3%;
  padding-left: 2.5%;
  justify-content: space-between;
  align-items: center;
  gap: 10%;
}

.headerContainer {
  width: 38%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 4px;
}

.header {
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #002d5d;
  margin: 0;
  display: block;
}

.subHeader {
  line-height: 30px;
  color: #002d5d;
  @extend .subtitle1;
}

.carMobileView {
  display: none;
}

.mobileButton {
  display: none;
}

.desktopButton {
  align-items: center;

  background-color: #002246;
  border: 1px solid #002246;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  color: #fff;
  display: flex;
  font-weight: 700;
  height: 48px;
  justify-content: center;
  padding: 12px 20px;
  text-decoration: none;
  width: 200px;
}

@media (max-width: 1024px) {
  .container {
    gap: 0;

    padding: 2% 3%;
  }

  .carDesktopView {
    width: 50%;
  }

  .headerContainer {
    gap: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    margin: 0;
    margin-top: 28px;
    padding-top: 24px;
    padding-inline-start: 16px;
    padding-bottom: 30px;
    width: 100%;
    border-radius: 0;
    gap: 0;
    flex-direction: column;
  }

  .subHeader {
    line-height: 30px;
    color: #002d5d;
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: var(--regularFontWeight);
  }

  .headerContainer {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    padding-inline: 16px;
  }

  .subHeader {
    line-height: 18px;
    color: #667085;
  }

  .carDesktopView {
    display: none;
  }

  .carMobileView {
    display: block;
    height: 93px;
    margin-inline-end: -90px;
    padding-bottom: 10px;
    width: 172px;
  }

  .desktopButton {
    display: none;
  }

  .mobileButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    width: 90%;
    height: 48px;
    background: #002246;
    border: 1px solid #002246;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: white;
    margin: 0 auto;
    text-decoration: none;
  }

  .mobileViewContainer {
    display: flex;
    flex-direction: column;
  }
}
