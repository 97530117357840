.container {
    width: 100%;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 90vh;
}
.content_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
}
.flex_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.button_container {
    height: 96px;
    position: sticky;
    bottom: 0;
    margin: -16px;
    width: 100vw;
}
