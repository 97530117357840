.container {
  background-color: #f9fafb;
  display: flex;
  flex-direction: column;
  padding: 4.5% 5.5%;
  text-align: center;
  justify-content: flex-start;
  gap: 24px;
}

.headerSection {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

@media (max-width: 768px) {
  .container {
    gap: 0;
  }
  .cardsContainer {
    flex-direction: column;
    width: 100%;
  }
}
