@import "src/theme/colors.module.scss";
@import "src/theme/dimensions.scss";

.container {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  border-style: dashed;
  border-color: $gray-200;
  border-width: 1px;
  justify-content: space-between;
  gap: 8px;
}
.closedContainer {
  align-items: center;
}
.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
}
.container:first-child {
  border-radius: 8px 8px 0px 0px;
}
.container:not(:first-child) {
  border-top-width: 0px;
}
.container:last-child {
  border-radius: 0px 0px 8px 8px;
}

.titleContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 100%;
  justify-content: flex-start;
}

.priceContainer {
  margin-inline-start: auto;
  display: flex;
  gap: 3px;
  align-items: baseline;
}

.btnContainer {
  all: unset;
  display: flex;
  height: 20px;
  width: 20px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  animation: 0.4s rollout;
}

.list {
  list-style-position: outside;
  color: $gray-600;
  font-size: 10px;
  padding-inline-start: 16px;
}
@keyframes rollout {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  0% {
    opacity: 0.3;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
