@import '/src/theme/dimensions.scss';

//.filters_container {
//     top: 0;
//    padding-inline-end: 24px;
//    height: fit-content;
//}
.banners_container_mobile {
     @media screen and (max-width: 850px) {
        display: block;
        position: relative;
        overflow: hidden;
        background: lightgrey;
        aspect-ratio: 1492/745 auto;
        margin-bottom: 10px;
        border-radius: 12px;
    }
}
.banners_container {
     aspect-ratio: 2039/320 auto;
    position: relative;
    overflow: hidden;
    background: lightgrey;
    border-radius: 12px;
}
.buy_used_cars_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    @media screen and (max-width: 850px) {
        gap: 8px;
    }
}
.cars_listing_container {
    //display: grid;
    //grid-template-columns: repeat(3, 1fr);
    //gap: 12px 4px;
    //width: fit-content;
    //@media screen and #{$largeDesktopView}{
    //    grid-template-columns: repeat(3, 1fr);
    //}
    //@media #{$tabletView} {
    //    grid-template-columns: repeat(2, 1fr);
    //}
    //@media screen and #{$mobileView} {
    //    grid-template-columns: repeat(1, 1fr);
    //}
}
.value_proposition {
    grid-column: 1 / -1;
    overflow: auto;
    white-space: nowrap;
    position: relative;
    display: none;
    @media screen and #{$mobileView} {
        overflow: unset;
        width: calc(100vw - 32px);
    }
    &.mobile {
        @media screen and #{$mobileView} {
            display: block;
        }
    }
    &.tablet {
        @media #{$tabletView} {
            display:block;
        }
    }
    &.desktop {
        @media screen and  #{$largeDesktopView} {
            display:block;
        }
    }
}
.testamonialContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 16px 0px 16px 0px;

    @media screen and (max-width: 850px) {
        justify-content: center;
        flex-direction: column;
        margin: 64px 0px 64px 0px;
    }
}

.testamonials {
    width: 75%;
    @media screen and (max-width: 850px) {
        width: 100%;
    }
}

.exbannerwrap {
    display: none;
    grid-column:  1 / -1;

    &.mobile {
        @media screen and #{$mobileView} {
            display:block;
        }
    }
    &.tablet {
        @media #{$tabletView} {
            display:block;
        }

    }
    &.desktop {
        @media screen and  #{$largeDesktopView} {
            display:block;
        }
    }
}
//.reserveseebanner {
//    flex: 1;
//    display:none;
//    &.reserveseebannermobile {
//        @media screen and #{$mobileView} {
//            display:block;
//        }
//    }
//    &.reserveseebannerdesktop {
//        @media screen and #{$desktopView}{
//            display:block;
//        }
//    }
//}

.hiddenloadingare {
    margin-top:-1700px;
    min-height: 1800px;
}
.sticky_mobile_search_bar {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: white;
    padding-top: 5px;
}
