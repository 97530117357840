.container {
  display: flex;
  gap: 8px;
  margin-block-end: 56px;
  padding: 16px;

  @media screen and (max-width: 850px) {
    flex-direction: column;
    padding-inline: 16px;
    height: 100%;

    .firstColumn {
      flex-direction: column-reverse;
      width: 100%;
      gap: 0px;
      padding-inline: 16px;
      min-width: unset;
    }
    .secoundColumn {
      position: relative;
      gap: 16px;
      margin-block-end: 50px;
      flex: 1;
    }
  }
}
.buttonContainer {
  position: fixed;
  width: 100vw;
  bottom: 0px;
  background: white;
  padding: 16px 12px;
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
  border-top: 1px solid #eaecf0;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 14px;
  height: 98px;
}

.submit_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
}
.spinner {
  right: 16px;
  top: 12px;
  position: absolute;
}

[dir="rtl"] .spinner {
  left: 16px;
  right: unset;
}
