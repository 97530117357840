.container {
  padding-inline: 5%;
  display: flex;
  gap: 16px;
  margin-block-end: 56px;

  .firstColumn {
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 400px;
  }

  .secoundColumn {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;
    padding-inline: 0px;
    height: 100%;

    .firstColumn {
      flex-direction: column-reverse;
      width: 100%;
      gap: 0px;
      padding-inline: 16px;
      min-width: unset;
    }
    .secoundColumn {
      position: relative;
      gap: 16px;
      margin-block-end: 50px;
      flex: 1;
    }
  }
}
