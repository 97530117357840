@import "src/theme/colors.module.scss";

.container {
  height: 200px;
  position: relative;
  padding: 50px;
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.firstBlock {
  background-color: #7ea2c4;
  height: 100px;
  width: 40%;
}
.secondBlock {
  background-color: grey;
  width: 30%;
  height: 48px;
}
.thirdBlock {
  background-color: $primary;
  width: 20%;
  height: 48px;
}
.similarCarsBorder {
  margin-top: 32px;
  position: relative;
  border-top: solid 1px $gray-200;
}
.similarCarsText {
  color: $gray-900;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  background-color: white;
  padding: 8px 8px;
}
.similarCarCards {
  padding-inline: 5%;
  padding-block: 32px;
  margin-bottom: 96px;
}
.similarCarsContainer {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px 16px;
  width: fit-content;
  @media screen and (min-width: 1101px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (min-width: 786px) and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 785px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
