.container {
  display: flex;
  flex-direction: column;
  padding: 7.8% 5.5%;
  gap: 48px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerDescriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header {
  color: #009b5d;
}

.subHeader {
  color: #002d5d;
  margin: 0;
  line-height: 30px;
  margin-top: 4px;
}

.loader {
  margin: auto;
}

//.carsContainer {
//  display: flex;
//  flex-direction: row;
//  flex-wrap: wrap;
//  gap: 2.5%;
//}

//.card {
//  width: 23%;
//  min-height: fit-content;
//}

//@media (max-width: 1024px) {
  //.carsContainer {
  //  width: 100%;
  //  gap: 15px;
  //}
  //
  //.card {
  //  width: 32%;
  //}
//}

@media (max-width: 768px) {
  .desktopActionButton {
    display: none;
  }

  .container {
    text-align: center;
  }

  .headerContainer {
    justify-content: center;
  }

  //.carsContainer {
  //  flex-direction: column;
  //  gap: 16px;
  //}

  //.card {
  //  width: 100%;
  //}
}
