@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";
.sticky_container {
  position: sticky;
  bottom: 110px;
  margin-bottom: 12px;
  display: flex;
  margin-inline: 16px;
  align-items: center;
  background-color: $error-25;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s,
  opacity 0.5s linear;
  border: 1px solid #fda29b;
  height: 52px;
}
.closed {
  visibility: hidden;
  opacity: 0;
}
