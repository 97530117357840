@import 'src/theme/colors.module.scss';
.youtube_container {
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    background-color: black;
    aspect-ratio: 1 / 1;
    z-index: 100;
}
.rating_category_cont {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    padding: 0px 8px;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 56%,
        rgba(0, 0, 0, 0.4) 61%,
        rgba(0, 0, 0, 0.85) 75%
    );
    cursor: pointer;
    .play_button {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: center;
        margin-bottom: 12px;
    }
    .rating_info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 10px;
        .category_chip {
            background-color: white;
            font-size: 14px;
            border: 1px solid $primary;
            font-weight: 400;
        }

        div {
            &:first-of-type {
                margin-bottom: 16px;
            }
            &:nth-of-type(2) {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                height: fit-content;
            }
        }
    }
}
.vid_pause_overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: flex;
    padding: 8x;
    color: white;
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 65%,
        rgba(0, 0, 0, 0.4) 70%,
        rgba(0, 0, 0, 0.85) 85%
    );
    div {
        text-align: start;
        display: flex;
        align-items: center;
        padding: 10px;
        z-index: 100000;
        img {
            margin: 0px 5px;
        }
    }
}

.rating_category_row {
    display: flex;
    justify-content: space-between;
    direction: ltr;
}
.hidden {
    display: none;
}
.thumbnail {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    img {
        width: 100%;
        object-fit: cover;
    }
}
