.h1 {
  font-size: 4.5rem;
  line-height: 5.625rem;
  letter-spacing: -2%;
  font-weight: var(--regularFontWeight);
}

.h2 {
  font-size: 3.75rem;
  line-height: 4.5rem;
  letter-spacing: -2%;
  font-weight: var(--regularFontWeight);
}

.h3 {
  font-size: 3rem;
  line-height: 3.75rem;
  letter-spacing: -2%;
  font-weight: var(--regularFontWeight);
}

.h4 {
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: var(--regularFontWeight);
}

.h5 {
  font-size: 1.875rem;
  line-height: 2.375rem;
  font-weight: var(--regularFontWeight);
}

.h6 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: var(--regularFontWeight);
}

.subtitle1 {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: var(--regularFontWeight);
}

.subtitle2 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: var(--regularFontWeight);
}

.body1 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: var(--regularFontWeight);
}

.body2 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: var(--regularFontWeight);
}

.caption {
  @apply text-xs leading-[1.125rem] font-normal
}

.overline {
  font-size: 0.9rem;
  text-transform: uppercase;
  line-height: 1.125rem;
  font-weight: var(--regularFontWeight);
}

.label {
  font-size: 0.625rem;
  line-height: 0.875rem;
  font-weight: var(--regularFontWeight);
}

.h1Medium {
  @extend .h1;
  font-weight: var(--mediumFontWeight);
}

.h2Medium {
  @extend .h2;
  font-weight: var(--mediumFontWeight);
}

.h3Medium {
  @extend .h3;
  font-weight: var(--mediumFontWeight);
}

.h4Medium {
  @extend .h4;
  font-weight: var(--mediumFontWeight);
}

.h5Medium {
  @extend .h5;
  font-weight: var(--mediumFontWeight);
}

.h6Medium {
  @extend .h6;
  font-weight: var(--mediumFontWeight);
}

.subtitle1Medium {
  @extend .subtitle1;
  font-weight: var(--mediumFontWeight);
}

.subtitle2Medium {
  @extend .subtitle2;
  font-weight: var(--mediumFontWeight);
}

.body1Medium {
  @extend .body1;
  font-weight: var(--mediumFontWeight);
}

.body2Medium {
  @extend .body2;
  font-weight: var(--mediumFontWeight);
}

.captionMedium {
  @extend .caption;
  @apply font-medium
}

.overlineMedium {
  @extend .overline;
  font-weight: var(--mediumFontWeight);
}

.h1SemiBold {
  @extend .h1;
  font-weight: var(--semiBoldFontWeight);
}

.h2SemiBold {
  @extend .h2;
  font-weight: var(--semiBoldFontWeight);
}

.h3SemiBold {
  @extend .h3;
  font-weight: var(--semiBoldFontWeight);
}

.h4SemiBold {
  @extend .h4;
  font-weight: var(--semiBoldFontWeight);
}

.h5SemiBold {
  @extend .h5;
  font-weight: var(--semiBoldFontWeight);
}

.h6SemiBold {
  @extend .h6;
  font-weight: var(--semiBoldFontWeight);
}

.subtitle1SemiBold {
  @extend .subtitle1;
  font-weight: var(--semiBoldFontWeight);
}

.subtitle2SemiBold {
  @extend .subtitle2;
  font-weight: var(--semiBoldFontWeight);
}

.body1SemiBold {
  @extend .body1;
  font-weight: var(--semiBoldFontWeight);
}

.body2SemiBold {
  @extend .body2;
  font-weight: var(--semiBoldFontWeight);
}

.captionSemiBold {
  @extend .caption;
  font-weight: var(--semiBoldFontWeight);
}

.overlineSemiBold {
  @extend .overline;
  font-weight: var(--semiBoldFontWeight);
}

.h1Bold {
  @extend .h1;
  font-weight: var(--boldFontWeight);
}

.h2Bold {
  @extend .h2;
  font-weight: var(--boldFontWeight);
}

.h3Bold {
  @extend .h3;
  font-weight: var(--boldFontWeight);
}

.h4Bold {
  @extend .h4;
  font-weight: var(--boldFontWeight);
}

.h5Bold {
  @extend .h5;
  font-weight: var(--boldFontWeight);
}

.h6Bold {
  @extend .h6;
  font-weight: var(--boldFontWeight);
}

.subtitle1Bold {
  @extend .subtitle1;
  font-weight: var(--boldFontWeight);
}

.subtitle2Bold {
  @extend .subtitle2;
  font-weight: var(--boldFontWeight);
}

.body1Bold {
  @extend .body1;
  font-weight: var(--boldFontWeight);
}

.body2Bold {
  @extend .body2;
  font-weight: var(--boldFontWeight);
}

.captionBold {
  @extend .caption;
  font-weight: var(--boldFontWeight);
}

.overlineBold {
  @extend .overline;
  font-weight: var(--boldFontWeight);
}

.labelMedium {
  @extend .label;
  font-weight: var(--mediumFontWeight);
}
