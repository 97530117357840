.slidern {
    :global .slick-track {
        display: flex !important;
    }
    :global .slick-slide {
        margin: 0 4px !important;
    }
    @media screen and (min-width: 785px) {
        padding-inline-end: 0px;
    }
}
