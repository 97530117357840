.container {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 24px 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
  rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 16px;
}

.icon {
  height: 60px;
  width: 60px;
  margin-bottom: 24px;
}

.title {
  line-height: 28px;
  color: #002d5d;
  margin-bottom: 4px;
}

.description {
  margin: 0;
  color: #344054;
  opacity: 1;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
  }
}
