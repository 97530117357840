@import "src/theme/dimensions.scss";
.container_sort_desktop_comp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding: -1px 12px;
.inner_container {
  display: none;
  gap: 0;
  width: 100%;
  @media screen and #{$desktopView} {
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: initial;
  }
}
}
