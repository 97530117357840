@import "src/theme/colors.module.scss";

.reserve_see_container {
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  position: relative;

  &[variant="card"] {
    gap: 10px;
    padding: 32px 0 12px 0;
  }

  @media (max-width: 767px) {
    gap: 0;
  }
}

.header_container {
  display: flex;
  gap: 12px;
  @media (max-width: 380px) {
    gap: 4px;
  }
}

$gap: 8px;
$padding: 20px 16px;

.content_container {
  display: flex;
  flex-direction: column;
  gap: $gap;
  padding: $padding;
  position: relative;

  &[variant="card"] {
    gap: 6px;
    margin: 0 16px 0 16px;
    padding: 0;
  }

  &[divider] {
    border-bottom: solid 1px $gray-400;
    padding-bottom: 11.6px;
  }

  &[skew] {
    &:after {
      content: "";
      transform: skew(6deg);
      width: 17px;
      height: 100%;
      position: absolute;
      background-color: $secondary;
      right: 0;
      top: 0;
    }
  }

  &[rtl="rtl"][skew] {
    &:after {
      transform: skew(-6deg);
      left: 0;
      right: unset;
    }
  }
}

.learn_more_button {
  outline: none;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 100%;
  padding: 8px 14px;
  cursor: pointer;
}

.modal {
  padding: 20px 24px;
}

.reserveModalCarContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.modalTypography {
  color: $secondary;
  display: flex;
  align-items: center;
}

.description {
  color: $secondary;
  margin: 8px 0 !important;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.itemHeaderContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.benefitsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.benefitContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: $secondary;
}

.icon {
  width: 16px;
  height: 16px;
}

.divider {
  margin: 16px 0 !important;
}

.title {
  color: $secondary;
}

.price {
  color: #667085;
}
