.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 33px 5%;
  background-color: white;
  justify-content: space-between;
}

.hamLogoContainer {
  display: flex;
  gap: 27px;
  align-items: center;
}

.linksSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 36px;
  margin-bottom: 0;

  padding-left: 2rem;
}

.logo {
  width: 160px;
  object-fit: contain;
  vertical-align: middle;

  &[dir="rtl"] {
    width: 140px;
    height: 40px;
    margin-inline-start: 7.5%;
  }
}

.logoAr {
  width: 140px;
  margin-inline-start: 7.5%;
}

.navItem {
  display: flex;
  margin: 0 8px;
  list-style: none;
}

.navLink {
  color: #00150c;
  cursor: pointer;
  text-decoration: none;
}

.navLink:hover {
  color: #00150cbc;
}

.whySylndr {
  color: #009b5d;
  display: none;
}

.hamburgerMenu {
  display: none;
}

.hamburgerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0 10px;
  cursor: pointer;
}

.drawerContainer {
  width: 300px;
  z-index: 9999999;
}

.langSelectorImage {
  align-self: flex-start;
}

@media (max-width: 850px) {
  .logo {
    width: 100px !important;
  }
  .logoAr {
    width: 100px;
  }

  .langSelector {
    display: none;
  }

  .linksSection {
    display: none;
  }

  .whySylndr {
    display: block;
  }

  .hamburgerMenu {
    display: block;
  }
  .change_language_layout {
    display: inherit;
    gap: 16px;
  }
}

@media (min-width: 850px) {
  .change_language_layout {
    display: grid;
    grid-template-areas: "start end";
  }
  .change_language_layout_img {
    grid-area: end;

    &[dir="ltr"] {
      grid-area: end;
    }
  }
  .change_language_layout_text {
    grid-area: start;

    &[dir="ltr"] {
      grid-area: start;
    }
  }
}

.disabled {
  cursor: default;
}
