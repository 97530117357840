@import "src/theme/colors.module.scss";
.outerContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 850px) {
    margin-inline: 16px;
  }
}
.title {
  color: $gray-700;
  @media screen and (max-width: 850px) {
    color: $secondary;
  }
}
.slidersContainer {
  padding: 24px 16px;
  border: 1px solid $gray-300;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.slider {
  display: flex;
  flex-direction: column;
  gap: 55px;

  .title {
    color: $gray-700;
  }
}

.dataContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .dataRow {
    display: flex;
    justify-content: space-between;

    .name {
      color: $gray-600;
    }

    .value {
      color: $gray-500;
    }
  }
}
