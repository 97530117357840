.section_container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  align-items: center;
  padding: 0 5.5% 7.8% 5.5%;
}

.content_container {
  position: relative;
  min-height: 162px;
  overflow: hidden;
  transition: all ease-in-out 0.2s;
  @media (max-width: 1000px) {
    min-height: 102px;
  }
}

.cards_container {
  display: grid;
  grid-template-columns: repeat(6, 200px);
  gap: 32px 16px;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(5, 200px);
  }
  @media (max-width: 1070px) {
    grid-template-columns: repeat(4, 200px);
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(4, 150px);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(3, 150px);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(3, 150px);
  }
  @media (max-width: 460px) {
    grid-template-columns: repeat(3, 100px);
  }
}
.view_more_container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: white;
}
.view_more_content_container {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
