@import 'src/theme/dimensions';
@import 'src/theme/colors.module.scss';

.container {
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .container {
        padding-top: 0;
        width: 100%;
    }
}

.testimonialContainer {
    margin: 64px 80px 64px 80px;
    @media #{$mobileView} {
        margin: 64px 16px 64px 16px;
    }
}
