@import "src/theme/colors.module.scss";
.idInputContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  margin-block-start: 4px;
}
.idInput {
  display: flex;
  border: 1px solid $gray-200;
  border-radius: 8px;
  width: 100%;
  justify-content: space-between;
  height: 68px;
  padding: 16px 8px;
}

.idTitleAndIcon {
  display: flex;
  gap: 4px;
  align-items: center;
}
.idImgLabel {
  color: #344054;
}
.idIconContainer {
  width: 32px;
  height: 32px;
  background: $gray-100;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addPhotoBtn {
  padding: 8px 14px;
  height: 36px;
  background: $white;
  border: 1px solid $gray-300;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: #344054;
}

.addPhotoBtn:disabled {
  color: $gray-300;
}
.fileContainer {
  width: 100%;
  border: 1px solid $gray-300;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  display: flex;
  flex: 1;
}
.thumbnail {
  width: 101px;
  height: 64px;
  border-radius: 6px;
}
.details {
  flex: 1;
  overflow: hidden;

  .detailsFirstRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .trashIcon {
      cursor: pointer;
    }

    .nameAndSize {
      max-width: 40%;

      .fileName {
        color: $gray-700;
      }

      .fileSize {
        color: $gray-500;
      }
    }
  }

  .detailsSecoundRow {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}
