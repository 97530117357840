@import 'src/theme/colors.module.scss';

.testimonials {
    background-color: $primary-25;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid var(--Primary-400, #33d896);
    @media screen and (min-width: 850px) {
        padding-inline-end: 16px;
        padding-inline-start: 16px;
    }

    h2 {
        text-align: center;
        margin-inline-end: 15px;
        line-height: 32px;
        font-size: 24px;
        color: $gray-800;
        padding: 32px 0px;
    }
    .separator_line {
        height: 1px;
        border-top: 1px solid var(--Primary-400, #33d896);
        margin-top: 32px;

        margin-inline-start: 16px;
        margin-inline-end: 16px;
    }
}
