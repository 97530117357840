@import 'src/theme/colors.module.scss';
@import 'src/theme/dimensions';

.container {
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    background-color: $gray-25;
    border: 1px solid $gray-300;
    position: relative;
    overflow: hidden;

    @media screen and #{$desktopView} {
        width: calc(95% + 30px);
    }
}

.ripple {
    position: absolute;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: $gray-300;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: sonar 3s linear infinite;
    z-index: 1;
}

@keyframes sonar {
    0% {
        transform: scale(0);
        opacity: 0.1;
    }
    100% {
        transform: scale(150);
        opacity: 0.4;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    position: relative;
    z-index: 2;
}

.title {
    color: $gray-700;
    text-transform: uppercase;
    font-weight: 500;
}
.description {
    color: $gray-500;
    position: relative;
    z-index: 2;
}
