@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";

.cardDetails {
  width: 100%;
  border: 1px solid $gray-200;
  border-radius: 16px;
  padding: 24px 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  @media screen and (max-width: $MOBILE_SCREEN) {
    max-width: 510px;
  }
}

.cardDetailsHeader {
  color: $secondary;
}

.cardDetailsFirstSection {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__carImg {
    width: 137px;
    height: 70px;
    margin-inline-end: 16px;
    border-radius: 8px;
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  .carDataContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;

    &__firstRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__secoundRow {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .priceAndHelpContainer {
        display: flex;
        justify-content: flex-start;
        flex: 1;

        .currency {
          color: $gray-500;
          display: inline-flex;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 400;

          .price {
            color: $primary;
            margin-inline-start: 2px;
            margin-inline-end: 4px;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 500;
          }
        }

        @media screen and (max-width: 1140px) {
          flex-direction: column;
          margin-inline-end: 6px;
        }
      }

      .whatsIncludedContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        margin-top: 4px;
        cursor: pointer;
        @media screen and (max-width: 850px) {
          margin-bottom: 24px;
        }
      }

      .whatsIncludedButton {
        color: $gray-500;
        text-decoration: underline;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 18px;
      }

      @media screen and (max-width: 1140px) {
        align-items: flex-start;
      }
    }

    &__thirdRow {
      display: flex;
      flex-direction: row;
      align-content: baseline;
      gap: 16px;
      align-items: center;

      div {
        display: flex;
        max-width: 91px;
        align-content: center;
        margin-inline-end: 10px;
        gap: 4px;
      }

      .specsText {
        white-space: pre;
        color: $gray-500;
      }

      .specIcon {
        width: 16.67px;
        height: 16.67px;

        &.calendarIcon {
          width: 15px;
        }
      }

      @media screen and (max-width: 1060px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    @media screen and (max-width: 850px) {
      gap: 5px;
    }
  }

  @media screen and (max-width: 850px) {
    width: 100%;
    margin-bottom: 24px;
    border: none;
    padding-bottom: 0px;

    .icon {
      width: 34px;
      height: 34 p;
      margin-inline-end: 16px;

      &.iconReversed {
        transform: rotateY(180deg);
      }
    }
  }
}
.strikethrough {
  text-decoration: line-through;
}
.primaryColor {
  color: $primary;
}
.secoundryColor {
  color: $secondary-500;
}
.warningColor {
  color: $warning-400;
}
.grey500Color {
  color: $gray-500;
}
.cardDetailsSecoundSection {
  border-top: 1px solid $gray-200;
  padding-block-start: 16px;
  margin-block-start: 18px;
  width: 100%;

  .fees {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    &:first-of-type {
      margin-top: 18px;
    }
  }
}

.cardDetailsThirdSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  padding-top: 18px;
  padding-bottom: 2px;

  .starIcon {
    width: 18.85px;
    height: 17.97px;
    margin-inline-end: 18.57px;
  }
}
.arrowIcon {
  cursor: pointer;

  &.reversed {
    transform: rotateY(180deg);
  }
}

.testDriveFees {
  display: flex;
  align-items: baseline;

  div {
    display: flex;
    margin-inline-start: 4px;
  }
}
