@import "src/theme/colors.module.scss";

.cant_find_car_container {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #eaecf0;
    box-shadow:
        0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    text-decoration: none;
}

.content_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    flex: 1;
    height: 100%;
    padding: 20px 16px;
}
.title_container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: $primary;
    align-items: center;
}

.install_car {
    outline: none;
    background: $primary;
    border: 1px solid $gray-300;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: $white;
    width: 100%;
    padding: 8px 14px;
    cursor: pointer;
}
