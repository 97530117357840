@import "src/theme/dimensions";
@import "src/theme/colors.module.scss";

.container {
  width: 100%;
  border: 1px solid $gray-200;
  border-radius: 16px;
  padding: 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
}
.title {
  color: $secondary-500;
}

.cardDetailsFirstSection {
  display: flex;
  justify-content: space-between;

  &__carImg {
    width: 100px;
    height: 56px;
    margin-inline-end: 16px;
    border-radius: 8px;
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  .carDataContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    &__firstRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__secoundRow {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .priceAndHelpContainer {
        display: flex;
        justify-content: flex-start;
        text-overflow: clip;
        flex: 1;

        .currency {
          color: $gray-500;
          display: inline-flex;

          .price {
            color: $primary-600;
            margin-inline-start: 2px;

            margin-inline-end: 4px;
          }
        }

        @media screen and #{$mobileView} {
          flex-direction: column;
          margin-inline-end: 6px;
        }
      }

      .whatsIncludedContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        cursor: pointer;
        @media screen and #{$mobileView} {
          margin-bottom: 24px;
        }
      }

      .whatsIncludedButton {
        color: $gray-500;
        text-decoration: underline;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 18px;
      }

      @media screen and (max-width: 1140px) {
        align-items: flex-start;
      }
    }

    &__thirdRow {
      display: flex;
      flex-direction: row;
      align-content: baseline;
      gap: 2.5%;
      align-items: center;

      div {
        display: flex;
        max-width: 91px;
        align-content: center;
        margin-inline-end: 10px;
        gap: 4px;
      }

      .specsText {
        white-space: pre;
        color: $gray-500;
      }

      .specIcon {
        width: 16.67px;
        height: 16.67px;

        &.calendarIcon {
          width: 15px;
        }
      }

      @media screen and (max-width: 1300px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    @media screen and #{$mobileView} {
      gap: 5px;
    }
  }

  @media screen and #{$mobileView} {
    width: 100%;
    margin-bottom: 24px;
    border: none;
    padding-bottom: 0px;
    flex: 1;
    .icon {
      width: 34px;
      height: 34 p;
      margin-inline-end: 16px;

      &.iconReversed {
        transform: rotateY(180deg);
      }
    }
  }
}

.secoundryColor {
  color: $secondary-500;
}
