@import "src/theme/colors.module.scss";
@import "src/theme/dimensions";

.container {
  display: flex;
  padding: 16px;
  border-radius: 8px;
  gap: 12px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  @media screen and #{$desktopView} {
    flex-direction: row;
    justify-content: space-between;
  }
}
.containerInner {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media screen and #{$desktopView} {
    white-space: nowrap;
    margin-inline-end: auto;
  }
}
.containerSold {
  background-color: $success-50;
  border: 1px solid $primary-300;
}
.containerPurchaseInProgress {
  background-color: $blue-dark-25;
  border: 1px solid $secondary-300;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and #{$mobileView} {
    width: 100%;
  }
}

.bottom {
  display: flex;
  align-items: center;
  gap: 8px;
}
