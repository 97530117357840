@import "src/theme/colors.module.scss";

.button {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  outline: none;
  color: $white;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.primary {
  @extend .button;
  background-color: $primary;
  border: solid 1px $primary;
}
.secondary {
  @extend .button;
  background-color: $primary-50;
  border: solid 1px $primary-50;
  color: #00673e;
}
.inverted {
  @extend .button;
  background-color: $secondary-600;
  border: solid 1px $secondary-600;
}
.disabled {
  @extend .button;
  background-color: $gray-200;
  cursor: not-allowed;
  border: solid 1px $gray-200;
}

.syldr-button {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  outline: none;
  color: $white;
  font-weight: 600;
}
// .primary {
//   background-color: $primary;
//   border: solid 1px $primary;
// }
// .secondary {
//   background-color: $primary-50;
//   border: solid 1px $primary-50;
//   color: #00673e;
// }
// .inverted {
//   background-color: $secondary-600;
//   border: solid 1px $secondary-600;
// }
// .disabled {
//   background-color: $gray-200;
//   cursor: not-allowed;
//   border: solid 1px $gray-200;
// }
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  width: 200px;
  height: 48px;
  background: #009b5d;
  border: 1px solid #009b5d;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: white;
  font-weight: 700;
  text-decoration: none;
}
.container:disabled {
  background-color: #d0d5dd;
  border: 1px solid #d0d5dd;
}

.inverted {
  background-color: #002246;
  border: 1px solid #002246;
}

.container:hover {
  color: white;
  opacity: 0.9;
}

.container.inverted:hover {
  color: white;
  opacity: 0.9;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
}
