@import 'src/theme/dimensions';
@import 'src/theme/colors.module.scss';

.input {
    border: 1px solid $gray-300;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px $gray-900-opac;
    padding-inline: 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 48px;
    &:focus {
        outline-color: $gray-300;
        outline-width: 1px;
    }

    &.erorr {
        border-color: $error-300;
    }
}
.otpInputsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}
.otpTick {
    margin: 0 8px;
}
.otpInput {
    width: 44px !important;
    height: 64px;
    border: solid 1px;
    border-color: #d0d5dd;
    border-radius: 8px;
    font-size: 28px;
    outline: none;
    color: $primary;
    font-weight: 500;
    &::-webkit-inner-spin-button,
    .no-spinners::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
    }
    &:focus {
        box-shadow: 0px 1px 2px #66e2b0;
        border-color: #66e2b0;
    }

    &.error {
        color: $error-300;
    }
}
.otpModalContentResendContainer {
    padding: 0 6px;
    margin-bottom: 32px;
    color: $gray-500;
}
.otpModalContentResend {
    display: inline;
    margin: 0 5px;
    cursor: pointer;
    border-bottom: 1px solid;
    color: $gray-500;
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}
.otpDisclaimer {
    padding: 0 6px;
    color: $gray-500;
}
.buttonDisabled {
    background-color: $gray-300;
    cursor: not-allowed;
}
.otpError {
    margin-bottom: 15px;
    color: $error-500;
}
.nameInput {
    margin-bottom: 5px !important;
}
.nameFieldContainer {
    margin-bottom: 48px;
}

.numberInputContainer {
    input[type='number'] {
        /* Firefox */
        -moz-appearance: textfield;
    }

    /* WebKit browsers (Chrome, Safari) */
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Edge */
    input[type='number']::-ms-inner-spin-button,
    input[type='number']::-ms-outer-spin-button {
        appearance: none;
    }
}
