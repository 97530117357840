@import 'src/theme/colors.module.scss';

.primaryBtn {
    background: $primary;
    color: $white;
    box-shadow: 0px 1px 2px $gray-900-opac;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    border-radius: 8px;
    color: $white;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.buttonDisabled {
    background-color: $gray-300;
    cursor: not-allowed;
}
